<template>
  <div class="file-upload-container">
    <div class="preview-area">
      <div v-for="(file, index) in previews" :key="index" class="preview-item">
        <img v-if="file.type.startsWith('image/')" :src="file.url" class="preview-image" />
        <div v-else class="preview-file">{{ file.name }}</div>
        <button class="remove-btn" @click="removeFile(index)">X</button>
      </div>
    </div>

    <input ref="fileInput" type="file" class="d-none" :accept="accept" :multiple="multiple" @change="processFiles" />

    <button @click="triggerFileInput" type="button" class="btn btn-default  ">
      <span class="btn-inner--icon">
        <i class="uil-image"></i>
      </span>
      <span class="btn-inner--text">{{ title }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
    title: {
      type: String,
      default: 'Dosya Ekle',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      previews: [],
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },

    removeFile(index) {
      this.files.splice(index, 1);
      this.previews.splice(index, 1);

      this.$emit("input", this.files);
    },

    removeAllFiles() {
      this.files=[]
      this.previews=[]

      this.$emit("input", this.files);
    },

    processFiles(event) {
      const selectedFiles = event.target.files;
      if (!selectedFiles.length) return;

      this.files = [...selectedFiles];

      // preview
      this.previews = this.files.map((file) => ({
        name: file.name,
        type: file.type,
        url: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
      }));
      this.$emit("input", this.files);
    },
  },
};
</script>

<style scoped>
.file-upload-container {
  position: relative;
  text-align: center;
}

.preview-area {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.preview-item {
  width: 100px;
  height: 100px;
  position: relative;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.preview-file {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  border-radius: 5px;
  font-size: 12px;
}
.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.remove-btn:hover {
  background: red;
}
</style>
