<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
                this.$t('report.company_dashboard.reports')
              }}
            </span>
          </li>
          <li> {{$t('menu.external_source_reports')}}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{$t('external_source.external_source_list')}}</span>
        </h2>
      </div>
      <div class="section-header-right">
        <button aria-expanded="false" class="btn btn-default" type="button" uk-toggle="target: #modal-add-report">
          <i class="uil-plus"></i> {{$t('general.add_file')}}
        </button>
      </div>
    </div>

    <!--Modal Profie-->
    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color:red;">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input v-model="filter.search" class="uk-search-input shadow-0 uk-form-small"
                     :placeholder="$t('general.search')" type="search"/>
            </div>
          </div>
          <sort-dropdown :sort-options="sortOption" :title="sortTitle" @sort-selected="sortOrder"/>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"/>

      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t("general.photo") }}</th>
              <th scope="col">{{ $t('general.first_name_and_last_name') }}</th>
              <th scope="col">{{ $t('general.title') }}</th>
              <th scope="col">{{ $t('general.file_type') }} </th>
              <th scope="col">{{ $t('general.description') }}</th>
              <th scope="col">{{ $t('general.transactions') }}</th>
            </tr>
            </thead>
            <tbody class="list">
            <template v-for="item in formattedItems">
              <tr>
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <img :alt="item.name" :src="item.photo_link" class="avatar  rounded-circle"/>
                      </div>
                    </div>
                  </div>
                </th>
                <td>{{ item.name }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.file_extension }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <div class="uk-flex">
                    <button :uk-tooltip="$t('general.download')" aria-expanded="false"
                            class="btn btn-icon btn-hover btn-sm btn-circle"
                            @click="downloadZipFile(item)">
                      <i class="uil-download-alt"/>
                    </button>
                    <button :uk-tooltip="$t('general.delete')" aria-expanded="false"
                            class="btn btn-icon btn-hover btn-sm btn-circle"
                            type="button"
                            uk-toggle="target: #modal-delete-report"
                            @click="selectItemToDelete(item)">
                      <i class="uil-trash text-danger"/>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>

    <!--    Modal-->
    <div id="modal-add-report" uk-modal @hidden="resetFormModal">
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">{{ $t('external_source.add_external_source') }}</h2>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <label class="uk-form-label" for="api_key"> {{ $t('general.title') }} <span class="required">*</span></label>
            <input id="api_key" v-model="form.reportTitle" class="uk-input" :placeholder="$t('general.title')"
                   type="text"/>
            <span v-if="validationErrors.reportTitle" class="error-text">{{ validationErrors.reportTitle }}</span>

          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="api_key">  {{ $t('general.provider_name') }} <span class="required">*</span></label>
            <input id="api_key" v-model="form.reportProvider" class="uk-input"
                   :placeholder=" $t('external_source.add_external_source_name')" type="text"/>
            <span v-if="validationErrors.reportProvider" class="error-text">{{ validationErrors.reportProvider }}</span>

          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="api_key"> {{ $t('general.description') }} </label>
            <input id="api_key" v-model="form.reportDesc" class="uk-input" :placeholder="$t('general.description')"
                   type="text"/>
          </div>
          <div class="uk-margin">
            <FileUpload ref="fileUploadRef" v-model="form.reportFiles" :values="form.reportFiles"
                        accept=".pdf,.doc,.docx,.xls,.xlsx"/>
            <span v-if="validationErrors.reportFiles" class="error-text">{{ validationErrors.reportFiles }}</span>
          </div>
        </div>

        <p class="uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button" @click="resetFormModal">
            {{ $t('general.cancel') }}
          </button>
          <button
              class="uk-button uk-button-primary"
              type="button"
              :disabled="isLoading"
              @click="addNewReport"
          >
            {{ $t('general.save') }}
            <span v-if="isLoading" uk-spinner="ratio: 0.5" class="uk-margin-small-left"></span>
          </button>

        </p>
      </div>
    </div>

  </div>
</template>

<script>
import reports, {ERROR, GET_ITEMS, LOADING, MODULE_NAME, SUCCESS} from "@/core/services/store/reports.module";

import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";
import SortDropdown from "@/view/components/dropdown/SortDropdown.vue";
import FileUpload from "@/view/components/input/fileUpload/fileUpload.vue";
import axios from "axios";
import { confirmDialogDelete } from "@/utils/confirmDialog";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportSurveyResults",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: {FileUpload, Pagination, DefaultLoading, SortDropdown},
  data() {
    return {
      pagination: null,
      page: 1,
      per_page: 10,
      formattedItems: [],
      error: null,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      sortTitle: this.$t("filters.order.sort"),
      sortOption: [
        {key: 'text-asc', sort: 'id', order: 'asc', label: this.$t("sorting.a_z")},
        {key: 'text-desc', sort: 'id', order: 'desc', label: this.$t("sorting.z_a")},
        {key: 'created_at-asc', sort: 'created_at', order: 'asc', label: this.$t("sorting.created_at_asc")},
        {key: 'created_at-desc', sort: 'created_at', order: 'desc', label: this.$t("sorting.created_at_desc")},
      ],
      form: {
        reportTitle: '',
        reportProvider: '',
        reportDesc: '',
        reportFiles: []
      },
      validationErrors: {},
      itemToDelete: null,
      isLoading: false
    };
  },
  computed: {
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
    user_loading() {
      return store.getters[_MODULE_NAME + "/" + LOADING];
    },
    errors() {
      return this.$store.getters[ERROR];
    },
  },
  methods: {
    async filterResult() {
      try {
        const filters = {
          like: this.filter.search,
          page: this.page,
          per_page: this.per_page,
        };

        const response = await store.dispatch(`${_MODULE_NAME}/${GET_ITEMS}`, {
          url: "api/report/external",
          filters: filters,
        });

        if (response.status) {
          const {data: items} = response;
          this.pagination = items;

          this.formattedItems = items.data.map(item => ({
            ...item,
            name: item.user.fullname,
            status: item.user.status_id === 0 ? "Pasif" : "Aktif",
            photo_link: item.user.photo_link,
          }));
        }

      } catch (error) {
        console.error("Error fetching the report data:", error);
      }
    },
    async downloadZipFile(file) {
      try {
        const response = await axios.get(`/api/report/external/${file.id}/download`, {
          responseType: 'blob'
        });

        // Dosya adını belirle
        const blob = new Blob([response.data], { type: 'application/zip' });

        // Tarayıcıda indirme işlemi başlat
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.createZipFileTitle(file)}.zip`; // Dosya adı
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          alert("Bağlantının süresi doldu. Lütfen tekrar deneyin.");
        } else {
          console.error("İndirme hatası:", error);
          alert("Dosya indirilemedi.");
        }
      }
    },
    createZipFileTitle(data) {
      if (data.title) {
        return data.title.includes(' ') ? data.title.split(' ').join('_') : data.title;
      }
      return data.created_at;
    },
    async selectItemToDelete(item) {
      const isConfirmed = await confirmDialogDelete({context: this});
      if(isConfirmed){
        try {
          await axios.delete(`api/report/external/${item.id}`)
        } catch (e) {
          console.error('catch', e)
        } finally {
          await this.filterResult()
        }
      }
      this.itemToDelete = item;
    },
    async addNewReport() {
      if (!this.validateForm()) return;

      this.isLoading=true

      let formData = new FormData();
      formData.append("title", this.form.reportTitle);
      formData.append("provider_name", this.form.reportProvider);
      formData.append("description", this.form.reportDesc);
      formData.append("file", this.form.reportFiles[0]);

      try {
       const res= await axios.post('api/report/external', formData)
        console.log("ress",res)
      } catch (e) {
        console.error('catch', e)
      } finally {
        this.isLoading=false
        await this.filterResult()
        this.resetFormModal()
      }

    },
    resetFormModal() {
      UIkit.modal("#modal-add-report").hide();

      this.$refs.fileUploadRef.removeAllFiles()

      this.form = {
        reportTitle: '',
        reportProvider: '',
        reportDesc: '',
        reportFiles: []
      }
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
    validateForm() {
      this.validationErrors = {};

      if (!this.form.reportTitle.trim()) {
        this.validationErrors.reportTitle = "Bu alan zorunludur.";
      }
      if (!this.form.reportProvider.trim()) {
        this.validationErrors.reportProvider = "Bu alan zorunludur.";
      }
      if (!this.form.reportFiles || this.form.reportFiles.length === 0) {
        this.validationErrors.reportFiles = "En az bir dosya yüklemelisiniz.";
      }

      return Object.keys(this.validationErrors).length === 0;
    },
  },
  mounted() {
    this.filterResult();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },

  },

};
</script>
<style scoped>
.error-text {
  color: red;
  font-size: 12px;
  display: block;
}
</style>

